import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import { wait } from '@testing-library/user-event/dist/utils';
import { useFormik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useCreateClientBank } from '../../../hooks/useCreateClientBank';
import { ButtonsPrimary, ButtonsTertiary } from '../../ComponentApp/buttoms/ButtonsXcala';
import {
  CheckBoxOutlineBlank,
  ContentCheckBox,
  TermsAndConditions,
  TextDeclaration,
} from '../../portfolio/rescuePortfolio/CardsResultRescue';
import {
  BankContainer,
  BtnContainer,
  ContainerModal,
  ModalContainer,
  MuntRescueModal,
  SubtitleModal,
} from '../../wallet/WalletStyledComponents';

const ModalEditBank = ({ handleCloseModal, bankDetail, accountTypes, banks }) => {
  const [isChecked, setIsChecked] = useState(false);

  const [createClientBank, { loading: loadingCreateBank, error: errorCreateBank }] =
    useCreateClientBank();

  const formik = useFormik({
    initialValues: {
      bank: bankDetail?.bank?.bank_id ?? '',
      typeAccount: bankDetail?.bankType?.bank_type_id ?? '',
      accountNumber: bankDetail?.account_number ?? '',
    },
    validationSchema: Yup.object({
      bank: Yup.string().required('Campo requerido'),
      typeAccount: Yup.string().required('Campo requerido'),
      accountNumber: Yup.string().required('Campo requerido'),
    }),
    validate: values => {
      const errors = {};
      const accountNumber = values.accountNumber || '';
      if (accountNumber.length > 20) {
        errors.accountNumber = 'El número de cuenta no puede tener más de 20 dígitos';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      try {
        const { data: createBank } = await createClientBank({
          variables: {
            data: {
              accountNumber: values.accountNumber,
              accountTypeId: Number.parseInt(values.typeAccount),
              bankId: Number.parseInt(values.bank),
            },
          },
        });
        handleCloseModal();
        await wait(300);
        toast.success(bankDetail ? 'Actualización exitosa' : 'Creación exitosa');
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleAccountNumberChange = event => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '').slice(0, 20);
    formik.setFieldValue('accountNumber', numericValue);
  };

  const handleChangeCheckBox = e => {
    setIsChecked(e.target.checked);
  };

  if (loadingCreateBank) {
  }
  if (errorCreateBank) {
  }

  return (
    <ContainerModal>
      <MuntRescueModal>
        <ModalContainer>
          <SubtitleModal>Modificar datos bancarios</SubtitleModal>

          <BankContainer>
            <Grid container spacing={2} paddingLeft={0}>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(formik.touched.bank && formik.errors.bank)}
                  fullWidth
                  helperText={formik.touched.bank && formik.errors.bank}
                  label='Banco'
                  name='bank'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.bank}
                  variant='standard'>
                  {banks?.getBanks?.map(bank => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(formik.touched.accountNumber && formik.errors.accountNumber)}
                  fullWidth
                  helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                  label='N° de cuenta'
                  name='accountNumber'
                  onBlur={formik.handleBlur}
                  onChange={handleAccountNumberChange}
                  type='text'
                  value={formik.values.accountNumber}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  error={Boolean(formik.touched.typeAccount && formik.errors.typeAccount)}
                  fullWidth
                  helperText={formik.touched.typeAccount && formik.errors.typeAccount}
                  label='Tipo de Cuenta'
                  name='typeAccount'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.typeAccount}
                  variant='standard'>
                  {accountTypes?.getBankAccountTypes?.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </BankContainer>

          <TermsAndConditions sx={{ margin: '0px 0px 10px 0px' }}>
            <ContentCheckBox gap={1}>
              <Stack marginTop={{ xs: '4px', md: '0px' }}>
                <CheckBoxOutlineBlank checked={isChecked} onChange={handleChangeCheckBox} />
              </Stack>
              <TextDeclaration>Aceptar</TextDeclaration>
            </ContentCheckBox>
          </TermsAndConditions>

          <BtnContainer>
            <ButtonsTertiary name='Cancelar' onClick={() => handleCloseModal()} disableElevation />
            <ButtonsPrimary
              name='Actualizar'
              disableElevation
              disabled={!isChecked}
              onClick={formik.handleSubmit}
            />
          </BtnContainer>
        </ModalContainer>
      </MuntRescueModal>
    </ContainerModal>
  );
};

export default ModalEditBank;
