import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { numberWithPoint } from '../../utils/numbers';
import { ButtonsPrimary } from '../ComponentApp/buttoms';

const BoxSectionPyment = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '5px 0px',
  boxSizing: 'border-box',
});
const BoxContent = styled(Stack)({
  backgroundColor: '#D7FCF4',
  display: 'flex',
  borderRadius: '8px',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '32px',
  height: '32px',
  margin: '0px',
});
const AtomoGraficaIconCaja = styled('img')(({ theme }) => ({
  width: '24px',
  height: '24px',
}));
const MuntPymenValue = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.secondary.text,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

const SelectRescueWithdrawal = ({
  balanceClient,
  amountRescue,
  valueSelect,
  handleAmountRescue,
  handleChange,
  clientBankDetail,
  handleOpenModalBank,
  handleOpenModal,
  isSubmitting,
}) => {
  const theme = useTheme();
  const [showHelperText, setShowHelperText] = useState(false);
  const [helperTextMessage, setHelperTextMessage] = useState('');

  const checkAmountRescue = () => {
    const amount = Number.parseInt(amountRescue.replace(/\D/g, '') || '0');
    if (amount < 500) {
      setHelperTextMessage('El monto mínimo de retiro es de $500');
      setShowHelperText(true);
    } else if (amount > balanceClient.balance) {
      setHelperTextMessage('El monto ingresado supera tu saldo disponible');
      setShowHelperText(true);
    } else {
      setShowHelperText(false);
      setHelperTextMessage('');
    }
  };

  useEffect(() => {
    if (amountRescue) {
      checkAmountRescue();
    }
  }, [amountRescue]);

  return (
    <form style={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <RadioGroup
          sx={{
            color: theme.palette.textLight.main,
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
          }}
          aria-labelledby='demo-error-radios'
          name='quiz'
          onChange={handleAmountRescue}
          value={valueSelect}>
          <FormControlLabel
            sx={{ color: theme.palette.textLight.main }}
            value={'50'}
            control={<Radio />}
            label='Retiro parcial'
          />
          <FormControlLabel
            sx={{ color: theme.palette.textLight.main }}
            value={'100'}
            control={<Radio />}
            label='Retiro total'
          />
        </RadioGroup>

        <TextField
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          disabled={valueSelect === '100'}
          hiddenLabel
          id='filled-hidden-label-normal'
          variant='standard'
          value={numberWithPoint(amountRescue)}
          onChange={handleChange}
        />
        {showHelperText && <FormHelperText error>{helperTextMessage}</FormHelperText>}

        <BoxSectionPyment flexDirection={'row'} gap={0.5}>
          <BoxContent
            sx={{
              backgroundColor: theme.palette.secondary.lightIcon,
            }}>
            <AtomoGraficaIconCaja src='./assets/images/cashBox.gif' />
          </BoxContent>
          <MuntPymenValue
            sx={{
              color: theme.palette.secondary.text,
            }}
            fontSize={theme.typography.button.fontSize}
            fontWeight={700}>
            {`$${numberWithPoint(balanceClient?.balance ?? 0)} Disponible`}
          </MuntPymenValue>
        </BoxSectionPyment>

        {clientBankDetail?.getUserBankDetail?.bank?.bank_name ? (
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            alignItems={'center'}
            justifyContent={'space-around'}
            gap={{ md: 2, xs: 1 }}>
            <Stack>
              <Typography
                sx={{
                  mt: 1,
                  color: theme.palette.primary.dark,
                  fontWeight: theme.typography.smallText.fontWeight,
                  fontSize: theme.typography.customButton.fontSize,
                }}>
                {`${clientBankDetail?.getUserBankDetail?.bank?.bank_name}`}
                {` - N° ${clientBankDetail?.getUserBankDetail?.account_number}`}
              </Typography>
            </Stack>
            <Stack>
              <Button
                onClick={() => handleOpenModalBank()}
                sx={{
                  marginTop: 1,
                  color: theme.palette.primary.main,
                  ':hover': { backgroundColor: theme.palette.white.main },
                }}
                startIcon={<EditIcon />}>
                Editar Cuenta
              </Button>
            </Stack>
          </Stack>
        ) : (
          <ButtonsPrimary name='Agregar Cuenta' onClick={() => handleOpenModalBank()} mb='5px' />
        )}
      </FormControl>

      <Stack width={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={3}>
        <Stack width={{ md: '50%', xs: '100%' }}>
          <ButtonsPrimary
            name='Retirar fondos de caja'
            onClick={() => handleOpenModal()}
            disabled={
              amountRescue === '' ||
              amountRescue <= 499 ||
              balanceClient.balance < Number.parseInt(amountRescue.replace(/\D/g, '')) ||
              amountRescue[0] === '0' ||
              clientBankDetail?.getUserBankDetail == null ||
              isSubmitting
            }
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default SelectRescueWithdrawal;
