import React from 'react';
import { Box, Modal } from '@mui/material';

export const ModalWallet = ({ open, onClose = () => {}, children }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }}
      sx={{
        backdrop: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }}>
      <Box sx={{ outline: 'none' }}>{children}</Box>
    </Modal>
  );
};
