import { Checkbox, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { numberWithPoint } from '../../../utils/numbers';
import { ButtonsPrimary, ButtonsTertiary } from '../../ComponentApp/buttoms';
import {
  BankContainer,
  BtnContainer,
  ContainerModal,
  FundText,
  FundTitle,
  ModalContainer,
  MsnAlert,
  MuntCashContiner,
  MuntContainer,
  MuntContext,
  MuntRescueModal,
  NameFundContainer,
  ParrafoAlert,
  SubtitleModal,
  TextImportante,
  TitleAlert,
  TitleContainer,
  TypeChange,
  ValueMunt,
  WarningAmber,
} from '../../wallet/WalletStyledComponents';

const IconsWarnning = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='warning_amber'>
        <g id='Vector'>
          <path
            d='M12.3044 6.49L19.8344 19.5H4.77444L12.3044 6.49ZM12.3044 2.5L1.30444 21.5H23.3044L12.3044 2.5Z'
            fill='rgb(1, 67, 97)'
          />
          <path d='M13.3044 16.5H11.3044V18.5H13.3044V16.5Z' fill='rgb(1, 67, 97)' />
          <path d='M13.3044 10.5H11.3044V15.5H13.3044V10.5Z' fill='rgb(1, 67, 97)' />
        </g>
      </g>
    </svg>
  );
};

const ModalWithdrawal = ({
  sectionTitle = false,
  typeFund = 'Fondo',
  titleFund = 'Xcala Balanceado Global',
  valorCuota = 0,
  handleCloseModal,
  amountRescue,
  clientBankDetail,
  handleSubmit,
}) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);
  const handleChangeCheckBox = e => {
    setIsChecked(e.target.checked);
  };
  const openTC = () => {
    var win = window.open(
      'https://sign-documents-staging.s3.amazonaws.com/documents/TyC.pdf',
      '_blank',
    );
    win.focus();
  };
  return (
    <ContainerModal>
      <MuntRescueModal>
        <ModalContainer>
          {sectionTitle && (
            <TitleContainer>
              <NameFundContainer>
                <FundText>{typeFund}</FundText>
                <FundTitle>{titleFund}</FundTitle>
              </NameFundContainer>
            </TitleContainer>
          )}
          <MuntCashContiner>
            <MuntContainer>
              <MuntContext>Monto a retirar</MuntContext>
              <ValueMunt>$ {numberWithPoint(amountRescue)}</ValueMunt>
            </MuntContainer>
            {valorCuota > 0 && (
              <TypeChange>{`*Tipo de cambio al 30/10/21 = $${valorCuota}`}</TypeChange>
            )}
          </MuntCashContiner>
          <SubtitleModal>¿Dónde hacemos el depósito?</SubtitleModal>

          <BankContainer>
            <TextField
              inputProps={{
                style: { fontSize: theme.typography.customButton.fontSize },
              }}
              fullWidth
              label='Banco'
              name='bank'
              disabled
              variant='standard'
              defaultValue={`${clientBankDetail.bank}`}
            />
            <TextField
              inputProps={{
                style: { fontSize: theme.typography.customButton.fontSize },
              }}
              fullWidth
              label='Cuenta'
              name='bank'
              disabled
              variant='standard'
              defaultValue={`N° ${clientBankDetail.accountNumber}`}
            />
          </BankContainer>

          <MsnAlert>
            <TitleAlert>
              <WarningAmber>
                <IconsWarnning />
              </WarningAmber>
              <TextImportante>Importante</TextImportante>
            </TitleAlert>
            <ParrafoAlert>
              El plazo de retiro comienza a correr el día hábil siguiente si tu solcitud fue
              realizada antes de las 4:00 PM y el día hábil subsiguiente si la realizas luego de esa
              hora.
            </ParrafoAlert>
          </MsnAlert>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
            marginTop={{ xs: '16px', md: '8px' }}>
            <Checkbox checked={isChecked} onChange={handleChangeCheckBox} />
            <Typography
              sx={{
                fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
              }}>
              Declaro entender los{' '}
              <Typography
                onClick={openTC}
                sx={{
                  fontSize: 'clamp(0.75rem, 0.5673rem + 0.7692vw, 1rem)',
                  cursor: 'pointer',
                }}
                component={'span'}
                color={theme.palette.secondary.main}
                fontWeight={theme.typography.titleText.fontWeight}>
                términos y condiciones del retiro
              </Typography>{' '}
              que estoy a punto de solicitar
            </Typography>
          </Stack>
          <BtnContainer>
            <ButtonsTertiary
              name='No por ahora'
              onClick={() => handleCloseModal()}
              disableElevation
            />
            <ButtonsPrimary
              name='Confirmar'
              disableElevation
              disabled={!isChecked}
              onClick={() => handleSubmit()}
            />
          </BtnContainer>
        </ModalContainer>
      </MuntRescueModal>
    </ContainerModal>
  );
};

export default ModalWithdrawal;
