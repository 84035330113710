import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetCashBalanceWithFallback } from '../../../../hooks/useGetCashBalanceWithFallback';
import { FormatCurrency } from '../../../../utils/numbers';
import Spinner from '../../../Spinner';
import {
  TypographyWallet,
  TypographyWalletAmount,
} from '../Layouts/StyledComponentsMenu';

export const WalletOpenDrawer = ({ subItem }) => {
  const { user } = useSelector((state) => state.auth);

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
    refetch: refetchBalance,
  } = useGetCashBalanceWithFallback();

  const cashBalance = balanceData;

  const walletAvailability = (item) => {
    if (item === 'Caja Disponible') {
      return cashBalance?.balance ? FormatCurrency(cashBalance?.balance) : '$0';
    }
    if (item === 'Dinero en Tránsito') {
      return cashBalance?.transitAmount
        ? FormatCurrency(cashBalance?.transitAmount)
        : '$0';
    }
  };

  useEffect(() => {
    if (user) {
      refetchBalance();
    }
  }, [user, refetchBalance]);

  if (loadingBalance) {
    return <Spinner />;
  }

  if (errorBalance) {
    throw new Error('Error component wallet drawer open');
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ textAlign: 'center', height: '100%' }}
    >
      <TypographyWallet>{subItem.btnNameSub}</TypographyWallet>
      <TypographyWalletAmount>
        {loadingBalance ? <Spinner /> : walletAvailability(subItem.btnNameSub)}
      </TypographyWalletAmount>
    </Stack>
  );
};
